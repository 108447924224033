<template>
  <div class="card shadow mt-4" style="padding-right:10px; padding-left:10px; padding-top:10px; padding-bottom:10px;">
       <h3 class="purple-title">Organization Users</h3>
        <!-- <router-link to="/admin-dashboard/payments">Details</router-link> -->
        <b-table hover responsive
      :items="items"
      :fields="fields"
      :current-page="currentPage"
      :per-page="perPage"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
      stacked="md"
      show-empty
      small
    >
      <template #cell(summary)="row">       
        <!-- <button style="margin-top:5px;" class="btn btn-danger" @click="showModal = true">X</button> -->
        <button @click="toggleManager(row.item, true)" v-if="row.item.isManager == 'no'" style="margin-top:5px;" class="btn purple-btn ml-2">Make Manager</button>
        <button @click="toggleManager(row.item, false)" v-else style="margin-top:5px;" class="btn btn-danger ml-2">Remove Manager</button>
         <Modal v-model="showModal" title="Delete User">
                <p>Are you sure you would like to delete this user?</p>
                <button @click="showModal = false" class="btn purple-btn"> &nbsp;&nbsp; Back</button>
                <!-- <button @click="deleteItem(row.item)" style="float:right;" class="btn btn-danger">Delete </button> -->
        </Modal>
      </template>
    </b-table>
     <div class="row">
        <div class="col-md-12">
                <a style="cursor:pointer; font-size:20px;" v-for="number in pageNumbers" :key="number" class="text-primary" @click="setPage(number)">{{number}}&nbsp;&nbsp;</a>
        </div>
    </div>
  </div>
</template>

<script>
import {managerService} from '../../_services/index'
import VueModal from '@kouts/vue-modal';
import '@kouts/vue-modal/dist/vue-modal.css'
export default {
    mounted(){
        managerService.getOrganizationUsers()
        .then((response) => {
            this.items = response.data;
        })
       // get all organization users

    },
    components:{
        'Modal':VueModal,
    },
    data(){
        return{
            isLoading: false,
            showModal: false,
            fullPage: true,
            items:[],

            fields:[
                {
                    key: 'firstName', label: 'First Name', sortable: true, sortDirection: 'desc'
                },
                {
                    key: 'lastName', label: 'Last Name', sortable: true, sortDirection: 'desc'
                },
                {
                    key: 'contactEmail', label: "Email", sortable: true, sortDirection: 'desc'
                },
                {
                    key: 'contactPhone1', label: 'Phone Number', sortable: true, sortDirection: 'desc'
                },
                {
                    key: 'summary', label: ''
                }
            ],
                totalRows: 1,
                currentPage: 1,
                perPage: 20,
                pageOptions: [5, 10, 15, { value: 100, text: "Show a lot" }],
                sortBy: '',
                sortDesc: false,
                sortDirection: 'asc',
                pageNumbers:[]
        }
    },
     methods:{
        setPage: function(number){
            this.currentPage = number;
        },
        toggleManager(item, makeManager ){
            managerService.toggleManager(item, makeManager)
            .then(() => {
                if(makeManager)
                    item.isManager = "yes";
                else
                    item.isManager = "no";
                this.$toast.success("User permissions successfully changed!", {
                    position: 'bottom-center',
                    timeout: 5000,
                    hideProgressBar: true
                }) 
            }, () => {
                 this.$toast.error("Failed to update user permissions.", {
                        position: 'bottom-center',
                        timeout: 5000,
                        hideProgressBar: true
                    }) 
            })
        },
        deleteItem: function(item){
            managerService.deleteUser(item)
            .then((response) => {
                this.items = response.data;
                this.showModal = false;
                  this.$toast.success("User Deleted Successfully!", {
                    position: 'bottom-center',
                    timeout: 5000,
                    hideProgressBar: true
                }) 
            }, () => { 
                this.showModal = false;
                  this.$toast.error("Failed to delete user.", {
                        position: 'bottom-center',
                        timeout: 5000,
                        hideProgressBar: true
                    }) 
            })
        }
    }
}
</script>

<style>

</style>