<template>
  <div>
      <SecondNavBar/>
      <main>
                <div class="container-fluid">
      <h1 class="purple-title text-center">My Organization</h1>
      <p class="text-center ">
          <img :src="organization.logoImageLink" class="img-fluid col-md-4" alt="organization logo">
      </p>
      <div class="row">
          <div class="col-md-2 offset-md-1 mt-4">
               <h5 class="purple-title">Business Details</h5>
          </div>
      </div>
      <div class="row">
          <div class="col-md-8 offset-md-1  mt-2">
              <p>Enter partner contact information on this page. If you would like to view users for this partner use the buttons at the bottom of the screen.</p>
          </div>
      </div>
      <div class="row mt-4">
          <label class="col-md-2 text-center offset-md-1" for="legalBusinessName">Legal Business Name:</label>
          <input id="legalBusinessName" class="col-md-2 form-control" type="text" v-model="organization.name"/>
      </div>
      <div class="row mt-4">
          <label for="organizationAddress" class="col-md-2 text-center offset-md-1">Address:</label>
          <input id="organizationAddress" class="col-md-3 form-control" type="text" v-model="organization.address"/>
      </div>
      <div class="row mt-4">
          <label class="col-md-2 text-center offset-md-1" for="organizationCity">City:</label>
          <input id="organizationCity" class="col-md-2 form-control" type="text" v-model="organization.city"/>
          <label for="orgnizationState" class="col-md-1 text-center">State:</label>
          <input id="organizationState" class="col-md-1 form-control" type="text" v-model="organization.state"/>
          <label for="organizationZip" class="col-md-1 text-center">Zip:</label>
          <input id="organizationZip" class="col-md-2 form-control" type="text" v-model="organization.zipCode"/>
      </div>
      <div class="row mt-4">
          <label for="contactName" class="col-md-2 text-center offset-md-1">Contact Person:</label>
          <input id="contactName" class="form-control col-md-3" type="text" v-model="organization.contactName"/>
          <label for="contactEmail" class="col-md-2 text-center">Contact Email:</label>
          <input id="contactEmail" class="form-control col-md-2" type="text" v-model="organization.contactEmail"/>
      </div>
      <div class="row mt-4">
          <label for="contactPhone" class="col-md-2 text-center offset-md-1">Contact Phone:</label>
          <input id="contactPhone" class="form-control col-md-3" type="text" v-model="organization.contactPhone1"/>
          <label for="contactSecondaryPhone" class="col-md-2 text-center ">Secondary Phone:</label>
          <input id="contactSecondaryPhone" class="form-control col-md-3" type="text" v-model="organization.contactPhone2"/>
      </div>
      <div class="row mt-4">
          <label for="registrationCode" class="col-md-2 text-center offset-md-1">Registration Code:</label>
          <input id="registrationCode" class="form-control col-md-2" disabled type="text" :value="organization.regCode"/>
          <p class="col-md-5">A Registration Code will allow new users to register with the Home For Life system
              and be connected to your partner account.
          </p>
      </div>
      <div class="row mt-4">
          <h5 class="col-md-5 offset-md-1 purple-title">Insurance and Certification Information</h5>
      </div>
      <div class="row mt-4">
          <label for="businessCertifications" class="col-md-2 text-center offset-md-1">Business Certifications:</label>
          <input id="businessCertifications" type="text" class="form-control col-md-3" v-model="organization.certifications"/>
          <span class="col-md-2 mt-1"><i>Comma separated values</i></span>
      </div>
      <div class="row mt-4">
          <label for="hasLiabilityInsurance" class="col-md-4 text-center offset-md-1">Does your business have Liability Insurance?</label>
          <select id="hasLiabilityInsurance" type="text" class="form-control col-md-1" v-model="organization.hasLiabInsurance">
              <option value="yes">Yes</option>
              <option value="no">No</option>
          </select>
      </div>
      <div class="row mt-4">
          <label for="hasWorkmansComp" class="col-md-4 text-center offset-md-1">Does your business have Workmans Compensation Insurance?</label>
          <select id="hasWorkmansComp" type="text" class="form-control col-md-1" v-model="organization.hasWorkCompInsurance">
              <option value="yes">Yes</option>
              <option value="no">No</option>
          </select>
      </div>
      <div class="row mt-4">
          <label for="hasWorkmansCompExemption" class="col-md-4 text-center offset-md-1">Does your business have a Workmans Compensation Exemption?</label>
          <select id="hasWorkmansCompExemption" type="text" class="form-control col-md-1" v-model="organization.hasWorkCompExemption">
              <option value="yes">Yes</option>
              <option value="no">No</option>
          </select>
      </div>
      <div class="row mt-4">
          <div class="col-md-2 offset-md-6">
              <button class="btn purple-btn mb-2" @click="saveOrganization"><FontAwesomeIcon icon="save"/> &nbsp;&nbsp;Save</button>
          </div>
          <div class="col-md-3">
              <button class="btn purple-btn mr-1 mb-2" @click="onPickFile"><FontAwesomeIcon icon="file-download"/> &nbsp;&nbsp;{{getLogoButtonText}}</button>
              <input type="file" style="display:none;" ref="fileInput2" accept="image/*" @change="onFilePicked"/>
          </div>
      </div>
      <div class="row mt-4">
          <div class="col-md-7 offset-md-2">
              <OrganizationUsersTable/>
          </div>
      </div>
       <div class="vld-parent">
                <loading :active.sync="isLoading" 
                :can-cancel="false" 
                
                ></loading>
               </div>    
     </div>
      </main>
  </div>
</template>

<script>
import {managerService} from '../../_services/index'
import SecondNavBar from '../../components/Clients/SecondNavBar'
import OrganizationUsersTable from '../../components/Manager/OrganizationUsersTable'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import 'vue-loading-overlay/dist/vue-loading.css'
import Loading from 'vue-loading-overlay'
export default {
    components:{
        SecondNavBar,
        OrganizationUsersTable,
        FontAwesomeIcon,
        Loading
    },
    data(){
        return {
           isLoading: false,
           organization:{
               id: "", name: "", address: "",
               city: "", state: "", zipCode: "",
               partnerType: "", contactName: "",
               contactPhone1: "", contactPhone2: "",
               contactEmail: "", regCode: "", status: "",
               hasLiabInsurance: "", hasWorkCompInsurance: "",
               hasWorkCompExemption: "", certifications: "",
               logoImageLink: ""
           } 
        }
    },
    mounted(){
        this.isLoading = true;
        managerService.getOrganizationDetails()
        .then((response) => {
            this.isLoading = false;
            this.organization = response.data;
            this.organization.hasLiabInsurance = response.data.hasLiabInsurance ? "yes" : "no";
            this.organization.hasWorkCompInsurance = response.data.hasWorkCompInsurance ? "yes" : "no";
            this.organization.hasWorkCompExemption = response.data.hasWorkCompExemption ? "yes" : "no";
        }, () => {
            this.isLoading = false;
        })
    },
    computed:{
        getLogoButtonText(){
            if(this.organization.logoImageLink){
                return "Change Organization Logo";
            }
            else{
                return "Upload Organization Logo"
            }
        }
    },
    methods:{
        saveOrganization: function(){
            var organization = this.organization
            organization.hasLiabInsurance = this.organization.hasLiabInsurance == 'yes' ? true : false;
            organization.hasWorkCompInsurance = this.organization.hasWorkCompInsurance == 'yes' ? true : false;
            organization.hasWorkCompExemption = this.organization.hasWorkCompExemption == 'yes' ? true : false;
            this.isLoading = true;
            managerService.editOrganization(organization)
            .then((response) => {
                this.isLoading = false;
                this.$toast.success("Successfully Updated Organization.", {
                    position: 'bottom-center',
                    timeout: 5000,
                    hideProgressBar: true
                })
                this.organization = response.data;
                this.organization.hasLiabInsurance = response.data.hasLiabInsurance ? "yes" : "no";
                this.organization.hasWorkCompInsurance = response.data.hasWorkCompInsurance ? "yes" : "no";
                this.organization.hasWorkCompExemption = response.data.hasWorkCompExemption ? "yes" : "no";
            }, () => {
                this.isLoading = false;
                this.$toast.error("Failed to save changes.", {
                    position: 'bottom-center',
                    timeout: 5000,
                    hideProgressBar: true
                })
            })
        },
        onPickFile(){
            this.$refs.fileInput2.click()
        },
        onFilePicked(event){
            const files = event.target.files
            const fileReader = new FileReader()
            fileReader.addEventListener('load', () => {
                this.imageUrl = fileReader.result
            })
            fileReader.readAsDataURL(files[0])
            this.image = files[0]
            
            this.savePicture(this.image);
            
            
        },
        savePicture(image){
            // emit loading
            this.$emit('toggle-loading', true);
            const formData = new FormData();
            formData.append("file", image, image.name)
           // roomService.saveImages(clientId, roomId, formData)
             managerService.uploadOrganizationLogo(this.organization.id, formData)
             .then(() => {        
                        this.$emit('toggle-loading', false);          
                        this.$toast.success("Logo Uploaded Successfully!", {
                        position: 'bottom-center',
                        timeout: 5000,
                        hideProgressBar: true

                    })                 
                    }, () => {    
                        this.$emit('toggle-loading', false);                   
                        this.$toast.error("Failed to upload logo.", {
                            position: 'bottom-center',
                            timeout: 5000,
                            hideProgressBar: true
                        })                 
                    }) 
        }
    }
}
</script>

<style>

</style>